import { converter } from "./common";
const adjustDateValues = function (data, fields) {
  if (Array.isArray(data) && Array.isArray(fields)) {
    data.forEach((item) => {
      fields.forEach((field) => {
        item[field] = new Date(item[field]);
      });
    });
  }
};
const adjustShipment = function (entity) {
  var adjustedEntity = entity || {};
  if (adjustedEntity.departure) {
    adjustedEntity.departure = new Date(adjustedEntity.departure);
  }
  if (typeof adjustedEntity.arrival == 'string' && adjustedEntity.arrival != null) {
    adjustedEntity.arrival = new Date(adjustedEntity.arrival);
  }
  if (Array.isArray(entity.packageDtos)) {
    adjustedEntity.storedPieces = entity.packageDtos.reduce((storedPieces, pkg) => {
      if (pkg.processStatus >= 3) {
        storedPieces += 1;
      }
      return storedPieces;
    }, 0);
    adjustedEntity.deliveredPieces = entity.packageDtos.reduce((deliveredPieces, pkg) => {
      if (pkg.processStatus >= 5) {
        deliveredPieces += 1;
      }
      return deliveredPieces;
    }, 0)
    adjustedEntity.inventory = adjustedEntity.storedPieces - adjustedEntity.deliveredPieces;
    adjustedEntity.weightPartnerLbs = 0;
    adjustedEntity.weightActualKg = 0;
    let receiptMap = {};
    if (entity.receipts) {
      for (let receipt of entity.receipts) {
        receiptMap[receipt.receiptCode] = receipt;
      }
    }
    for (let pkg of adjustedEntity.packageDtos) {
      adjustedEntity.weightPartnerLbs += pkg.weightPartnerLbs || 0;
      adjustedEntity.weightActualKg += pkg.weightActualKg || 0;
      pkg.shipmentReferenceCode = entity.referenceCode;
      pkg.awbCode = entity.awbCode;
      let receipt = receiptMap[pkg.receiptCode];
      if (receipt) {
        pkg.receiptReferenceCode = receipt.referenceCode;
      }
    }
  }
  if (adjustedEntity.weightPartnerLbs) {
    adjustedEntity.weightPartnerKg = converter.tokg(adjustedEntity.weightPartnerLbs);
  }
  if (adjustedEntity.weightActualKg) {
    adjustedEntity.weightActualLbs = converter.tolbs(adjustedEntity.weightActualKg);
  }
  return adjustedEntity;
};
const adjustPackage = function (entity) {
  if (entity.weightPartnerLbs) {
    entity.weightPartnerKg = converter.tokg(entity.weightPartnerLbs);
  }
  if (entity.weightActualKg) {
    entity.weightActualLbs = converter.tolbs(entity.weightActualKg);
  }
  if (entity.shipmentDto) {
    entity.shipmentReferenceCode = entity.shipmentDto.referenceCode;
    entity.awbCode = entity.shipmentDto.awbCode;
  }
  if (entity.receiptDto) {
    entity.receiptReferenceCode = entity.receiptDto.referenceCode;
  }
  return entity;
};
export default {
  adjustDateValues,
  adjustShipment,
  adjustPackage,
};
