import http, { Service } from "./common";

const resource = "shipment";
class ShipmentService extends Service {
  async startShipment(id) {
    return http.post(`/${resource}/start`, { id });
  }
  async getNextShipmentCode(agentCode) {
    return http.get(`/${resource}/getNextShipmentCode`, { params: { agentCode } });
  }
  async getAgentShipments(agentCode) {
    const response = await http.get(`/${resource}/getAgentShipments`, { params: { agentCode } });
    return response.data;
  }
  async getDocuments(shipmentId, filterOptions) {
    let params = this.createQueryParams(filterOptions);
    let url = `${resource}/${shipmentId}/documents`;
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    const response = await http.get(url);
    return response.data;
  }
  async addPackage(shipmentId, packageCode) {
    const data = {
      shipmentId,
      packageCode,
    };
    return http.post(`/${resource}/addPackage`, data);
  }
  async addPackages(shipmentId, packages) {
    const data = {
      id: shipmentId,
      packageIds: packages,
    };
    return http.post(`/${resource}/addPackages`, data);
  }
  async removePackages(shipmentId, packages) {
    const data = {
      id: shipmentId,
      packageIds: packages,
    };
    return http.put(`/${resource}/removePackages`, data);
  }
  async storePrintLog(type, shipmentId, packageId) {
    let data = {
      type,
      id: shipmentId,
      packageId,
    };
    return http.post(`/${resource}/print`, data);
  }
  async uploadDocument(blob, values) {
    const formData = new FormData();
    formData.append('file', blob, values['documentName']);
    for (let key in values) {
      formData.append(key, values[key]);
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    return http.post(`/${resource}/document`, formData, { headers });
  }
  async downloadDocument(entity) {
    try {
      const response = await http.get(`/${resource}/document/${entity.id}`, {
        responseType: 'blob'
      });
      let fileUrl = window.URL.createObjectURL(response.data);
      let fileLink = document.createElement('a');

      fileLink.href = fileUrl;
      fileLink.setAttribute('download', entity.documentName);
      document.body.appendChild(fileLink)
      fileLink.click();
      // Cleanup
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileUrl);

      // Open the downloaded file automatically (works if browser allows)
      // setTimeout(() => {
      //   window.open(fileUrl);
      // }, 500);
      return response;
    } catch (error) {
      console.error('Error downloading document:', error);
      throw error;
    }
  }
  async deleteDocument(id) {
    return http.delete(`/${resource}/document/${id}`);
  }
}

export default new ShipmentService(resource);
